import './styles.scss';
import React, {memo, useState, useEffect} from 'react';
import Slider from "react-slick";


const DataRoomRoadmap = () => {

    const [width, setWidth] = useState<number>(0);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        handleWindowSizeChange();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const renderSlider = () => {
        if(width < 768){
            return (
                <div className="dataRoomRoadmapSlider">
                            
                    <div className="dataRoomRoadmapSlide">
                        <div className="dataRoomRoadmapSlideInside">
                            <div className="dataRoomRoadmapDate">
                                <span>9 August - Now</span>
                            </div>
                            <div className="dataRoomRoadmapContent">
                                <p>August 2023</p>
                                <ol>
                                    <li>Onboarding update</li>
                                    <li>Testing of the free version</li>
                                    <li>Sections update</li>
                                    <li>Adapting the product to market needs</li>
                                    <li>50 promotional ads pictures creation </li>
                                    <li>25 promotional ads video creation and editing</li>
                                    <li>Transition to Adapty</li>
                                    <li>Amplitude setting</li>
                                </ol>
                            </div>
                        </div>
                        
                    </div>

                    <div className="dataRoomRoadmapSlide">
                        <div className="dataRoomRoadmapSlideInside">
                            <div className="dataRoomRoadmapDate">
                                <span>September</span>
                            </div>
                            <div className="dataRoomRoadmapContent">
                                <p>September 2023</p>
                                <ol>
                                    <li>The beginning of full-scale monetization</li>
                                    <li>Implementing of subscriptions and access levels</li>
                                    <li>Hypothesis testing</li>
                                    <li>Introduction of audio courses</li>
                                    <li>Onboarding page optimization</li>
                                </ol>
                                
                            </div>
                        </div>
                    </div>

                    <div className="dataRoomRoadmapSlide">
                        <div className="dataRoomRoadmapSlideInside">
                            <div className="dataRoomRoadmapDate">
                                <span>October</span>
                            </div>
                            <div className="dataRoomRoadmapContent">
                                <p>October 2023</p>
                                <ol>
                                    <li>Focus on working with retension and increasing MRR.</li>
                                    <li>Target - $15,000 MRR</li>
                                    <li>Integration of the Professions section (first B2B contracts)</li>
                                    <li>Upgrading AI Chat and AI Support during studying process</li>
                                    <li>Automation of content filling Books and Courses</li>
                                </ol>
                            </div>
                        </div>                          
                    </div>

                    <div className="dataRoomRoadmapSlide">
                        <div className="dataRoomRoadmapSlideInside">
                            <div className="dataRoomRoadmapDate">
                                <span>November</span>
                            </div>
                            <div className="dataRoomRoadmapContent">
                                <p>November 2023</p>
                                <ol>
                                    <li>Scaling of working advertising companies, reaching 25.000$ MRR</li>
                                    <li>Filling the app with a content relevant to Amplitude events</li>
                                    <li>Application performance optimization</li>
                                </ol>
                            </div>
                        </div>                          
                    </div>
                    <div className="dataRoomRoadmapSlide">
                        <div className="dataRoomRoadmapSlideInside">
                            <div className="dataRoomRoadmapDate">
                                <span>December</span>
                            </div>
                            <div className="dataRoomRoadmapContent">
                                <p>December 2023</p>
                                <ol>
                                    <li>Web-to-APP funnel development</li>
                                    <li>Ad campaigns scaling, reaching $40,000 MRR</li>
                                    <li>Integrations with influencers</li>
                                    <li>Filling the Professions section with a content</li>
                                </ol>
                            </div>
                        </div>                          
                    </div>
                    
                </div>
            )
        } else{
            return (
                <Slider className="dataRoomRoadmapSlider" {...settings}>
                            
                    <div className="dataRoomRoadmapSlide">
                        <div className="dataRoomRoadmapSlideInside">
                            <div className="dataRoomRoadmapDate">
                                <span>9 August - Now</span>
                            </div>
                            <div className="dataRoomRoadmapContent">
                                <p>August 2023</p>
                                <ol>
                                    <li>Onboarding update</li>
                                    <li>Testing of the free version</li>
                                    <li>Sections update</li>
                                    <li>Adapting the product to market needs</li>
                                    <li>50 promotional ads pictures creation </li>
                                    <li>25 promotional ads video creation and editing</li>
                                    <li>Transition to Adapty</li>
                                    <li>Amplitude setting</li>
                                </ol>
                            </div>
                        </div>
                        
                    </div>

                    <div className="dataRoomRoadmapSlide">
                        <div className="dataRoomRoadmapSlideInside">
                            <div className="dataRoomRoadmapDate">
                                <span>September</span>
                            </div>
                            <div className="dataRoomRoadmapContent">
                                <p>September 2023</p>
                                <ol>
                                    <li>The beginning of full-scale monetization</li>
                                    <li>Implementing of subscriptions and access levels</li>
                                    <li>Hypothesis testing</li>
                                    <li>Introduction of audio courses</li>
                                    <li>Onboarding page optimization</li>
                                </ol>
                                
                            </div>
                        </div>
                    </div>

                    <div className="dataRoomRoadmapSlide">
                        <div className="dataRoomRoadmapSlideInside">
                            <div className="dataRoomRoadmapDate">
                                <span>October</span>
                            </div>
                            <div className="dataRoomRoadmapContent">
                                <p>October 2023</p>
                                <ol>
                                    <li>Focus on working with retension and increasing MRR.</li>
                                    <li>Target - $15,000 MRR</li>
                                    <li>Integration of the Professions section (first B2B contracts)</li>
                                    <li>Upgrading AI Chat and AI Support during studying process</li>
                                    <li>Automation of content filling Books and Courses</li>
                                </ol>
                            </div>
                        </div>                          
                    </div>

                    <div className="dataRoomRoadmapSlide">
                        <div className="dataRoomRoadmapSlideInside">
                            <div className="dataRoomRoadmapDate">
                                <span>November</span>
                            </div>
                            <div className="dataRoomRoadmapContent">
                                <p>November 2023</p>
                                <ol>
                                    <li>Scaling of working advertising companies, reaching 25.000$ MRR</li>
                                    <li>Filling the app with a content relevant to Amplitude events</li>
                                    <li>Application performance optimization</li>
                                </ol>
                            </div>
                        </div>                          
                    </div>
                    <div className="dataRoomRoadmapSlide">
                        <div className="dataRoomRoadmapSlideInside">
                            <div className="dataRoomRoadmapDate">
                                <span>December</span>
                            </div>
                            <div className="dataRoomRoadmapContent">
                                <p>December 2023</p>
                                <ol>
                                    <li>Web-to-APP funnel development</li>
                                    <li>Ad campaigns scaling, reaching $40,000 MRR</li>
                                    <li>Integrations with influencers</li>
                                    <li>Filling the Professions section with a content</li>
                                </ol>
                            </div>
                        </div>                          
                    </div>
                
                </Slider>
            )
        }
    }

    const isMobile = width <= 768;

    const settings = {
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
    };


    return (
        <section className="dataRoomRoadmap">
            <div className="dataRoomContainer">
                <div className="dataRoomRoadmapBox">
                    <h2 className="dataRoomRoadmapTitle">Road Map 2023</h2>
                    <div className="dataRoomRoadmapSliderbox">
                        {renderSlider()}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default memo(DataRoomRoadmap);